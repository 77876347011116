<template>
    <b-container fluid>
        <h2 class="text-center my-4">Nova knjiga amortizacije</h2>
        <b-row>
            <b-col class="flex-center">
                <b-form v-on:submit.prevent="submitForm" class="w-75">
                    <b-form-group :label-cols-md="4"
                                  label="Period:"
                                  label-for="period">
                        <b-form-input id="period"
                                      name="period"
                                      required
                                      type="text"
                                      size="sm"
                        ></b-form-input>
                    </b-form-group>
                    <b-btn variant="primary" type="submit" class="pull-right">Potvrdi</b-btn>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'CreateAmortizationBook',
        data() {
            return {
                document_date: null
            }
        },
        methods: {
            submitForm: function (e) {
                const formData = new FormData(e.target);
                formData.append('client_id', this.$parent.selectedClient.id);
                formData.append('document_date', this.$moment(this.document_date).format("YYYY-MM-DD"));
                axiOsta.post(api.amortizationBooks, formData).then(response => {
                    if(response.status === 201) {
                        this.$router.push('/amortization_books/' + response.data.id);
                    }
                });
            },
        }
    }
</script>